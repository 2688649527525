@import './variables.scss';

.overlays{
	.mapboxgl-ctrl{
		position: absolute;
		bottom: 2rem;
		right: 1rem;
		opacity: 0.7;
		&:hover{
			opacity: 1
		}

		.mapboxgl-ctrl-icon{
			width: 24px;
			height: 24px;
			opacity: 0.7;
		}
	}

	.anticon-loading{
		position: absolute;
		top: 40%;
		left: 50%;
		color: white;
		font-size: 2em;
		z-index: 100;
	}

	.mapboxgl-marker {
		width: 0em;
		height: 0em;
		z-index: 1;

		.marker-icon{
			cursor: pointer;
			transform: translate(-50%, -50%);

			.marker-popup{
				position: absolute;
				bottom: calc(100% + 1em);
				left: 50%;
				transform: translateX(-50%);
				background: rgba(0,0,0,0.7);
				color: white;
				min-width: 10em;
				max-width: 20em;
				width: auto;
				padding: 0.75em 1.25em;
				display: none;
				border-radius: 0.4em;
				
				&:after{
					content: '';
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					width: 0; 
					height: 0; 
					border-left: 0.4em solid transparent;
					border-right: 0.4em solid transparent;
					border-top: 0.5em solid rgba(0,0,0,0.9);
				};

				&.-popup-hover{ min-width: 10em }
				&.-popup-open{ min-width: 20em }
			}

			&:hover{
				.marker-popup{
					&.-popup-hover{
						display: block;
					}
				}
			};

			&[data-open="true"]{
				.marker-popup{
					&.-popup-open{
						display: block;
					}
					&.-popup-hover{
						display: none;
					}
				}
			}
		}

		&:hover{
			z-index: 2
		};
	}
}

.mapboxgl-control-container{
	display: none;
}

.map-container{

	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0);
		z-index: 2;
		transition: background 0.7s ease-out;
		pointer-events: none;
	}
	
	&.-is-loading{
		pointer-events: none;
		
		&:after{
			content: '';
			background: rgba(0,0,0,0.7);
			transition-duration: 0.25s;
		}
		
	}

	.anticon-loading{
		text-indent: 3
	}
}