@import "~@style/_mixins.scss";

.global-header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0.5em 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 100;

	> span{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 33.3%;
		text-align: center;

		&:first-child{ justify-content: flex-start }
		&:last-child{ justify-content: flex-end }

		> .anticon{
			color: white;
		}

		> * {
			margin:0 0.5em;
		}
	}

	.anticon{
		cursor: pointer;

		&.anticon-login,
		&.anticon-menu{
			@include anticonSize(1.4em)
		}

		&.anticon-user{
			@include anticonSize(1.2em)
		}
	}

	.breadcrumbs{
		color: white;
		font-size: 0.8em;
		opacity: 0.8
	}

	.logo{
		width: 9.3rem;
		height: 2rem;
		display: block;
	}
}

.global-nav{
	.ant-drawer-body {
		> * {
			display: block;
			padding: .4em 0;
		}

		.group{
			margin-top: 1em;

			> * {
				display: block;
				padding: .2em 0;
			}
			
			&:before{
				content: attr(data-name);
				display: block;
				font-size: 0.8em;
				text-transform: uppercase;
				opacity: 0.5;
				margin-bottom: 0.5em
			};

			&.-settings{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 24px;
				font-size: 0.9em;
			}
		}
	}
}

.user-nav{
	.ant-popover-inner-content{
		display: block;
		text-align: right;
		font-size: 0.8em;

		> * {
			display: block;
			margin-bottom: 0.3em;

			&.name{
				font-size: 1.2em;
			}
		}

		hr{
			opacity: 0.3;
			margin: 1em 0;
		}

		> a {
			display: inline-block;
			margin-left: 1.5em
		}

		.ant-avatar{
			width: 18px;
			height: 18px;
		}
	}
}

.marker {
	.marker-icon{
		width: 0.7em;
		height: 0.7em;
		border-radius: 50%;
		border: 1px solid white;
		box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
		font-size: 0.85em;
		font-weight: 100;
		transition: all 0.2s;
		text-align: center;

		.marker-popup{
			span {
				display: block;
				width: 100%;
				//white-space: pre;
				margin-bottom: 0.2em;
				&:last-child{
					margin-bottom: 0
				}
			}

			&.-popup-hover{ white-space: nowrap }

			&.-popup-open{
				.title{
					font-size: 1.2em;
					font-weight: 600
				}

				.subtitle{
					font-size: 0.8em;
					text-transform: uppercase;
					opacity: 0.6;
				}

				hr{
					margin: 0.75em 0;
					border-width: 1px;
					border-top: none;
					border-bottom: 1px solid rgba(255,255,255,0.2);
					border-left: none;
					border-right: none;
				}

				.evslogo,
				>.anticon{
					position: absolute;
					bottom: calc(100% + 0.4rem);
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}

		&:hover,
		&[data-open="true"]{
			width: 1.1em;
			height: 1.1em;
		};

		&.-client{
			background: rgb(235,85,60)
		}

		&.-office{
			background: rgb(90,200,230);

			.-popup-open{
				.evslogo{
					font-size: 2rem
				}

				.info{
					margin-top: 1em;
					font-size: 0.9em;
					opacity: 0.8;
				}
			}

			.subtitle{
				& + span{
					margin-top: 0.8em;
				}

				.streetview{
					margin-left: 0.5em
				}
			}
		}

		&.-staff{
			background: rgb(62, 185, 57);

			.-popup-open{
				max-width: 30rem;
				>.anticon{
					font-size: 1.5rem;
				}
			}

			.person{
				margin-bottom: 0;
				white-space: nowrap;
				
				>*{
					margin: 0 0.4em;
				}
				
				> span {
					width: auto;
					display: inline-block;
					//width: auto;
				}

				> .ant-divider{
					opacity: 0.3
				}
			}

			.contact{
				
			}
		}
	}
}

.marker-tooltip{

	.ant-tooltip-inner {
		> * {
			display: block;
			text-align: center;
			font-size: 0.8em;
			font-weight: 100
		}

		hr{
			opacity: 0.3
		}

	}

	&.-office{
		.address {
			font-size: 0.7em;
			opacity: 0.8

		}
	}
}

.evslogo{
	margin: 0;
	width: 1em;
	height: 0.2em;

	svg{
		position: absolute;
		top: 0;
		left: 0;
		display: block !important;
		width: 100%;
		height: 100%;
	}

	&.-minimal{
		height: 1em;
	}
}

.global-search{
	width: 10em;
	transition: all 0.3s;
	opacity: 0.85;
	

	.ant-input,
	.ant-select-selection__placeholder{
		font-size: 0.9em !important;
		font-weight: 100 !important;
	}

	&.ant-select-focused{
		opacity: 1;
		width: 15em;
	}
}