@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700&display=swap');

body{
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 200;
}

#root{
	background: hsl(213, 9%, 21%);
	position: relative;
	width: 100vw;
	height: 100vh;
	font-weight: 100;
}