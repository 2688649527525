@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700&display=swap);
.overlays .mapboxgl-ctrl {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  opacity: 0.7; }
  .overlays .mapboxgl-ctrl:hover {
    opacity: 1; }
  .overlays .mapboxgl-ctrl .mapboxgl-ctrl-icon {
    width: 24px;
    height: 24px;
    opacity: 0.7; }

.overlays .anticon-loading {
  position: absolute;
  top: 40%;
  left: 50%;
  color: white;
  font-size: 2em;
  z-index: 100; }

.overlays .mapboxgl-marker {
  width: 0em;
  height: 0em;
  z-index: 1; }
  .overlays .mapboxgl-marker .marker-icon {
    cursor: pointer;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .overlays .mapboxgl-marker .marker-icon .marker-popup {
      position: absolute;
      bottom: calc(100% + 1em);
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.7);
      color: white;
      min-width: 10em;
      max-width: 20em;
      width: auto;
      padding: 0.75em 1.25em;
      display: none;
      border-radius: 0.4em; }
      .overlays .mapboxgl-marker .marker-icon .marker-popup:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 0.4em solid transparent;
        border-right: 0.4em solid transparent;
        border-top: 0.5em solid rgba(0, 0, 0, 0.9); }
      .overlays .mapboxgl-marker .marker-icon .marker-popup.-popup-hover {
        min-width: 10em; }
      .overlays .mapboxgl-marker .marker-icon .marker-popup.-popup-open {
        min-width: 20em; }
    .overlays .mapboxgl-marker .marker-icon:hover .marker-popup.-popup-hover {
      display: block; }
    .overlays .mapboxgl-marker .marker-icon[data-open="true"] .marker-popup.-popup-open {
      display: block; }
    .overlays .mapboxgl-marker .marker-icon[data-open="true"] .marker-popup.-popup-hover {
      display: none; }
  .overlays .mapboxgl-marker:hover {
    z-index: 2; }

.mapboxgl-control-container {
  display: none; }

.map-container:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
  -webkit-transition: background 0.7s ease-out;
  transition: background 0.7s ease-out;
  pointer-events: none; }

.map-container.-is-loading {
  pointer-events: none; }
  .map-container.-is-loading:after {
    content: '';
    background: rgba(0, 0, 0, 0.7);
    -webkit-transition-duration: 0.25s;
            transition-duration: 0.25s; }

.map-container .anticon-loading {
  text-indent: 3; }

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 200; }

#root {
  background: #31353a;
  position: relative;
  width: 100vw;
  height: 100vh;
  font-weight: 100; }

.global-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100; }
  .global-header > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3%;
    text-align: center; }
    .global-header > span:first-child {
      justify-content: flex-start; }
    .global-header > span:last-child {
      justify-content: flex-end; }
    .global-header > span > .anticon {
      color: white; }
    .global-header > span > * {
      margin: 0 0.5em; }
  .global-header .anticon {
    cursor: pointer; }
    .global-header .anticon.anticon-login svg, .global-header .anticon.anticon-menu svg {
      width: 1.4em;
      height: 1.4em; }
    .global-header .anticon.anticon-user svg {
      width: 1.2em;
      height: 1.2em; }
  .global-header .breadcrumbs {
    color: white;
    font-size: 0.8em;
    opacity: 0.8; }
  .global-header .logo {
    width: 9.3rem;
    height: 2rem;
    display: block; }

.global-nav .ant-drawer-body > * {
  display: block;
  padding: .4em 0; }

.global-nav .ant-drawer-body .group {
  margin-top: 1em; }
  .global-nav .ant-drawer-body .group > * {
    display: block;
    padding: .2em 0; }
  .global-nav .ant-drawer-body .group:before {
    content: attr(data-name);
    display: block;
    font-size: 0.8em;
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 0.5em; }
  .global-nav .ant-drawer-body .group.-settings {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px;
    font-size: 0.9em; }

.user-nav .ant-popover-inner-content {
  display: block;
  text-align: right;
  font-size: 0.8em; }
  .user-nav .ant-popover-inner-content > * {
    display: block;
    margin-bottom: 0.3em; }
    .user-nav .ant-popover-inner-content > *.name {
      font-size: 1.2em; }
  .user-nav .ant-popover-inner-content hr {
    opacity: 0.3;
    margin: 1em 0; }
  .user-nav .ant-popover-inner-content > a {
    display: inline-block;
    margin-left: 1.5em; }
  .user-nav .ant-popover-inner-content .ant-avatar {
    width: 18px;
    height: 18px; }

.marker .marker-icon {
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 0.85em;
  font-weight: 100;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  text-align: center; }
  .marker .marker-icon .marker-popup span {
    display: block;
    width: 100%;
    margin-bottom: 0.2em; }
    .marker .marker-icon .marker-popup span:last-child {
      margin-bottom: 0; }
  .marker .marker-icon .marker-popup.-popup-hover {
    white-space: nowrap; }
  .marker .marker-icon .marker-popup.-popup-open .title {
    font-size: 1.2em;
    font-weight: 600; }
  .marker .marker-icon .marker-popup.-popup-open .subtitle {
    font-size: 0.8em;
    text-transform: uppercase;
    opacity: 0.6; }
  .marker .marker-icon .marker-popup.-popup-open hr {
    margin: 0.75em 0;
    border-width: 1px;
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-left: none;
    border-right: none; }
  .marker .marker-icon .marker-popup.-popup-open .evslogo,
  .marker .marker-icon .marker-popup.-popup-open > .anticon {
    position: absolute;
    bottom: calc(100% + 0.4rem);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .marker .marker-icon:hover, .marker .marker-icon[data-open="true"] {
    width: 1.1em;
    height: 1.1em; }
  .marker .marker-icon.-client {
    background: #eb553c; }
  .marker .marker-icon.-office {
    background: #5ac8e6; }
    .marker .marker-icon.-office .-popup-open .evslogo {
      font-size: 2rem; }
    .marker .marker-icon.-office .-popup-open .info {
      margin-top: 1em;
      font-size: 0.9em;
      opacity: 0.8; }
    .marker .marker-icon.-office .subtitle + span {
      margin-top: 0.8em; }
    .marker .marker-icon.-office .subtitle .streetview {
      margin-left: 0.5em; }
  .marker .marker-icon.-staff {
    background: #3eb939; }
    .marker .marker-icon.-staff .-popup-open {
      max-width: 30rem; }
      .marker .marker-icon.-staff .-popup-open > .anticon {
        font-size: 1.5rem; }
    .marker .marker-icon.-staff .person {
      margin-bottom: 0;
      white-space: nowrap; }
      .marker .marker-icon.-staff .person > * {
        margin: 0 0.4em; }
      .marker .marker-icon.-staff .person > span {
        width: auto;
        display: inline-block; }
      .marker .marker-icon.-staff .person > .ant-divider {
        opacity: 0.3; }

.marker-tooltip .ant-tooltip-inner > * {
  display: block;
  text-align: center;
  font-size: 0.8em;
  font-weight: 100; }

.marker-tooltip .ant-tooltip-inner hr {
  opacity: 0.3; }

.marker-tooltip.-office .address {
  font-size: 0.7em;
  opacity: 0.8; }

.evslogo {
  margin: 0;
  width: 1em;
  height: 0.2em; }
  .evslogo svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block !important;
    width: 100%;
    height: 100%; }
  .evslogo.-minimal {
    height: 1em; }

.global-search {
  width: 10em;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0.85; }
  .global-search .ant-input,
  .global-search .ant-select-selection__placeholder {
    font-size: 0.9em !important;
    font-weight: 100 !important; }
  .global-search.ant-select-focused {
    opacity: 1;
    width: 15em; }

.ant-tooltip.auth-tooltip {
  font-weight: 100;
  font-size: 0.75em;
  white-space: pre; }
  .ant-tooltip.auth-tooltip .ant-tooltip-inner {
    text-align: right;
    min-height: 0; }

